import { NgModule } from '@angular/core';
import { SrFeSupplierMgmtRoutingModule } from './sr-fe-supplier-mgmt-routing.module';
import {  BasicService, ContainerModule, httpInterceptorProviders  } from 'sr-fe-core';
import { environment } from 'src/environments/environment';
import { SMPTableComponent } from './s-m-p-table/s-m-p-table.component';
import { InvoiceService } from './provider/invoice.service';

@NgModule({
  declarations: [SMPTableComponent],
  providers: [
    httpInterceptorProviders,InvoiceService
  ],
  imports: [
    SrFeSupplierMgmtRoutingModule,
    ContainerModule
  ]
})

export class SrFeSupplierMgmtModule {
  constructor(protected base: BasicService) {
    base.forRoot(environment);
  }
}
