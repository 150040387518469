export const environment = {
  production: false,
  heartBeat: {
    url: "https://insite.supplierportal.applexus.com/api/",
    timeout: 30000
  },
  sr: {
    url: "https://insite.supplierportal.applexus.com/api/",
    cross_url: "https://insite.supplierportal.applexus.com/api/"
  },
  supplierAPIUrl:'https://insite.supplierportal.applexus.com/api/pos'
};
