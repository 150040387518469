import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { uniqueId } from "lodash";
import { environment } from "../../../../../src/environments/environment";
@Injectable()
export class InvoiceService {
  static invoiceTableData: any[];
  static invoiceHederDetails: any = {};
  static InvoiceDetails: any[] = [];
  static PurchaseOrderList: any[];
  static softcopyData: any;
  static tableType;
  static isPoBasedInvoice: boolean;

  static overlayTableCoordinates: any[];
  static supplierAPIUrl = environment.supplierAPIUrl;
  fileUploadUrl: string =
    "https://aider.applexus.com/api/v1/invoice-extraction";
  fileUploadResponse: any;

  static reviewInvoice: any;

  constructor(private _httpClient: HttpClient) {}

  uploadInvoiceFile(file: any) {
    const formData = new FormData();
    formData.append("file", file);
    const headers = new HttpHeaders()
      .set("Client-Api", "64625492ba494631fcebd1c60dd49937")
      .set("x-auth-token", "12db91b6b3a94f89c93ded12d8386eb4");
    // .set('x-doc-schema', '10002')

    return this._httpClient.post(this.fileUploadUrl, formData, { headers });
  }

  getImage(guid) {
    const url = `https://aider.applexus.com/api/v1/document-rawdata/${guid}`;
    const headers = new HttpHeaders()
      .set("Client-Api", "64625492ba494631fcebd1c60dd49937")
      .set("x-auth-token", "12db91b6b3a94f89c93ded12d8386eb4")
      .set("x-doc-schema", "10002");

    return this._httpClient.get(url);
  }

  getLineItems(uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl +  "/invoice/line-items",
      { uid: uid }
    );
  }

  getInvoiceList(advancedSearch = {}) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/list",
      {
        advancedSearch: advancedSearch,
      }
    );
    // return this._httpClient.get("assets/files/invoice.json");
  }

  getInvoicePdf(uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/get-pdf",
      { uid: uid }
    );
  }

  saveCollabMessage(uid, message) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/collab-message/save",
      { uid: uid, message: message }
    );
  }

  deleteCollabMessage(msgId) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/collab-message/delete",
      { id: msgId }
    );
  }

  uploadAttachment(fileName, fileType: string, base64: any, uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/upload",
      { fileName: fileName, fileType: fileType, base64: base64, uid: uid }
    );
  }
  deleteAttachment(attachmentId) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/delete",
      { attachmentId: attachmentId }
    );
  }

  getAttachments(uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/list",
      { uid: uid }
    );
  }

  getAttachmentById(uid, attachmentId) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/id",
      { uid: uid, attachmentId }
    );
  }

  createInvoiceByPdf(fileName, pdfString) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/pdf",
      {
        fileName: fileName,
        base64String: pdfString,
      }
    );
  }
  createInvoiceByForm(data) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl +"/invoice/create-invoice/",
      { data: data }
    );
  }
  createInvoiceFormFields() {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/fields",
      {}
    );
  }
}
